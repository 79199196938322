<template>
    <div class="services-main-page">
        <div class="service">
            <h1 class="title">
                <div class="text">
                    Sillas para oficina
                </div>
                <div
                    data-aos="fade-down" 
                    data-aos-duration="1000"
                    class="line-left"></div>
                <div
                    data-aos="fade-right"
                    data-aos-delay="500"
                    data-aos-duration="1000" 
                    class="line-bottom"></div>
            </h1>
            <div class="container-all">
                <div class="container-img img-1">
                    <!-- BIG IMAGE -->
                </div>
                <div class="info">
                    <div class="container-text">
                        <!-- Text -->
                        <div class="text">
                            <h2 class="phrase">
                                Cómodas, modernas y de mayor movimiento y flexibilidad en el trabajo
                            </h2>
                            <p class="description">
                                Fabricamos y comercializamos sillas para oficinas, ejecutivas, secretariales, interlocutoras, ergonómicas, entre otras.
                            </p>
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-duration="1000" 
                            class="container-img img-2">
                            <!-- SMALL IMAGE -->
                        </div>
                    </div>
                    <div class="container-action-button">
                        <router-link 
                            tag="button" to=""
                            class="action-button">
                            SABER MÁS
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.services-main-page {
    padding: 55px 0px 55px 0px;

    .service {
        // Title
        .title {
            @extend %title-border;
        }

        .container-all {
            // Big image
            .img-1 {
                width: 100%;
                height: 250px;
                margin-bottom: 2rem;
                background-image: url(~@/assets/images/mobiliario-de-oficina/mobiliario-1.jpg);
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;

                @media (min-width: 760px) {
                    height: 500px
                }

                @media (min-width: 1024px) {
                    height: 480px;
                    max-width: 800px
                }

                @media (min-width: 1200px) {
                    margin: 0px auto 2rem auto;
                    max-width: 870px
                }
                @media (min-width: 1900px) {
                    max-width: 1000px;
                    height: 600px 
                }
            }

            .info {
                // Text
                .container-text {
                    margin-bottom: 1.5rem;

                    .text {                
                        .phrase {
                            font-size: 1.2rem;
                            font-family: $SourceSansPro-LightItalic;
                            letter-spacing: 1px
                        }
                        @media (min-width: 760px) {
                            .phrase {
                                font-size: 1.8rem;
                                margin-bottom: 1.5rem
                            }

                            .description {
                                font-size: 1.3rem
                            }
                        }

                        @media (min-width: 1024px) { 
                            width: 60%
                        }
                    }

                    .img-2 {
                        @media (min-width: 1024px) {
                            width: 40%;
                            max-width: 300px;
                            height: 480px;
                            margin-top: -330px;
                            border: 20px solid #FFFFFF;
                            background-image: url(~@/assets/images/mobiliario-de-oficina/banner_office_furniture.jpg);
                            background-size: cover;
                            background-position: center center;
                            background-repeat: no-repeat
                            // -webkit-box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.3);
                            // -moz-box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.3);
                            //  box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.3);
                        }

                        @media (min-width: 1200px) {
                            max-width: 350px;
                            height: 550px;
                            margin-top: -360px;
                            margin-right: -100px
                        }
                        @media (min-width: 1900px) {
                            max-width: 400px;
                            height: 650px;
                            margin-top: -420px;
                            margin-right: -110px
                        }
                    }
                    
                    @media (min-width: 760px) {
                        margin-bottom: 2.5rem
                    }

                    @media (min-width: 1024px) {
                        display: flex;
                        justify-content: space-between
                    }
                    @media (min-width: 1900px) {
                       margin-bottom: 0rem
                    }
                }
                // Button
                .container-action-button {
                    .action-button {
                        font-family: $SourceSansPro-SemiBold;
                        width: 100%;
                        max-width: 350px;
                        font-size: 1.1rem;
                        border: 1px solid $main-red-1;
                        color: $main-red-1;
                        background-color: #FFFFFF;
                        padding: 15px 30px 15px 30px;
                        letter-spacing: 5px;
                        cursor: pointer;
                        @include transition(all 0.5s);
                        &:focus { outline: none }

                        &:hover {
                            background-color: $main-red-1;
                            color: #FFFFFF
                        }
                    }
                }
            }

            @media (min-width: 1200px) {
                max-width: 870px;
                margin: 0% auto
            }
            @media (min-width: 1900px) {
                // border: 1px solid green;
                max-width: 1000px
            }
        }
    }

    @media (min-width: 760px) {
        padding: 70px 0px 70px 0px
    }

    @media (min-width: 1280px) {
        padding: 120px 0px 120px 0px
    }
}

</style>


