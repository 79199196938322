<template>
    <div class="show-product-gallery">  
        <h1 class="title">
            <div class="text">
                Gallery
            </div>
            <div
                data-aos="fade-down" 
                data-aos-duration="1000"
                class="line-left"></div>
            <div
                data-aos="fade-right"
                data-aos-delay="500"
                data-aos-duration="1000" 
                class="line-bottom"></div>
        </h1>
        <div class="container-slider">
            <!-- SLIDER -->
            <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
                <div 
                    class="slide" 
                    v-for="(slide, index) in slides" 
                    :key="index" 
                    :class="`slide--${index}`">
                    <img :src="slide" />
                </div>
            </agile>
            <agile class="thumbnails" ref="thumbnails" :options="options2" :as-nav-for="asNavFor2">
                <div 
                    class="slide slide--thumbniail" 
                    v-for="(slide, index) in slides" 
                    :key="index" 
                    :class="`slide--${index}`" 
                    @click="$refs.thumbnails.goTo(index)">
                    <img :src="slide" />
                </div>
                <template slot="prevButton">
                    <i class="material-icons">chevron_left</i>
                </template>
                <template slot="nextButton">
                    <i class="material-icons">chevron_right</i>
                </template>
            </agile>
        </div>
    </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
 
export default {
    components: {
        agile: VueAgile
    },     
    props: ['url_slides'],
    data() {
        return  {
            asNavFor1: [],
			asNavFor2: [],
			options1: {
				dots: false,
				fade: true,
				navButtons: false
			},
			
			options2: {
				autoplay: true,
				centerMode: true,
				dots: false,
				navButtons: false,
				slidesToShow: 3,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 5
                        }
                    },
                    // {
                    //     breakpoint: 1000,
                    //     settings: {
                    //         navButtons: true
                    //     }
                    // }
                ]
			},
			slides: [
				'https://images.unsplash.com/photo-1453831362806-3d5577f014a4?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
				'https://images.unsplash.com/photo-1496412705862-e0088f16f791?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
				'https://images.unsplash.com/photo-1506354666786-959d6d497f1a?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
				'https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
				'https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
				'https://images.unsplash.com/photo-1472926373053-51b220987527?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
				'https://images.unsplash.com/photo-1497534547324-0ebb3f052e88?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ'
			]
        }
    },
	mounted () {
		this.asNavFor1.push(this.$refs.thumbnails)
		this.asNavFor2.push(this.$refs.main)
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.show-product-gallery {
     @extend %default-container-paddings; 
     .title {
         @extend %title-border;
     }

    .container-slider {
        .main { margin-bottom: 30px }

        .thumbnails {
            margin: 0 -5px;
            width: calc(100% + 10px);
        }

        .agile__nav-button {
            background: transparent;
            border: none;
            // color: #ccc;
            cursor: pointer;
            font-size: 24px;
            transition-duration: 0.3s;
        }

        .thumbnails {
            .agile__nav-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%)
            }
            .agile__nav-button--prev { left: -45px }
            .agile__nav-button--next { right: -45px }
        }

        .agile__nav-button:hover { color: #888 }

        .agile__dot {
            margin: 0 10px;
            button {
                background-color: #eee;
                border: none;
                border-radius: 50%;
                cursor: pointer;
                display: block;
                height: 10px;
                font-size: 0;
                line-height: 0;
                margin: 0;
                padding: 0;
                transition-duration: 0.3s;
                width: 10px
            }
        }

        .agile__dot--current button, .agile__dot:hover button { background-color: #888 }

        .slide {
            align-items: center;
            box-sizing: border-box;
            color: #fff;
            display: flex;
            height: 300px;
            justify-content: center;

            @media (min-width: 760px) {
                height: 450px
            }
            @media (min-width: 1024px) {
                height: 550px
            }
            @media (min-width: 1200px) {
                height: 650px
            }
        }

        .slide--thumbniail {
            cursor: pointer;
            height: 100px;
            padding: 0 5px;
            transition: opacity 0.3s;
            &:hover { opacity: 0.75 }
        }

        .slide img {
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%
        }

        @media (min-width: 1200px) {
            max-width: 1100px;
            margin: 0% auto
        }
    }
}
</style>

