<template>
    <div class="office-furniture">
        <generic-bg-color></generic-bg-color>
        <!-- Options -->
        <boxes-services
            :full_top="true"
            :data_box="data_box">
        </boxes-services>
        <!-- SERVICES Gallery Generics -->
        <!-- Sillas -->
        <gallery-generic :gallery_data="gallery_chairs">
        </gallery-generic>
        <!-- Recepción -->
        <gallery-generic :gallery_data="gallery_reception">
        </gallery-generic>
        <!-- Salas de espera -->
        <gallery-generic :gallery_data="gallery_waiting_room">
        </gallery-generic>
        <!-- Muebles colaborativos -->
        <gallery-generic :gallery_data="gallery_collaborative_spaces">
        </gallery-generic>
        <!-- Salas de juntas -->
        <gallery-generic :gallery_data="gallery_meeting_rooms">
        </gallery-generic>
        <!-- Divisiones -->
        <gallery-generic :gallery_data="gallery_office_divisions">
        </gallery-generic>
        <!-- Muebles operativos -->
         <gallery-generic :gallery_data="gallery_operative_furniture">
        </gallery-generic>       
        <!-- Muebles gerenciales -->
         <gallery-generic :gallery_data="gallery_managerial_furniture">
        </gallery-generic>  


        <div class="container container-indu">
            <!-- Show product -->
            <!-- <show-product-banner>    
            </show-product-banner> -->
            <!-- Show product gallery -->
            <!-- <show-product-gallery>
            </show-product-gallery> -->
        </div>

        <!-- FOOTER -->
        <main-footer></main-footer>
    </div>
</template>

<script>
import BannerMainPageComponent from '@/components/Generics/BannerMainPageComponent'
import ServiceMainPageComponent from '@/components/Generics/ServiceMainPageComponent'
import ShowProductBannerComponent from '@/components/Generics/ShowProduct/ShowProductBannerComponent'
import ShowProductGalleryComponent from '@/components/Generics/ShowProduct/ShowProductGalleryComponent'

export default {
	metaInfo: {
		title: 'Muebles de Oficina',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{ name: 'keywords', content:'industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Muebles de Oficina'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card  
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Industrias pico | Muebles para Oficina en Bucaramanga | Escritorios para Oficinas | Sillas para Oficinas | Divisiones para Oficinas | Divisiones en Vidrio Bucaramanga' },
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Industrias pico | Muebles para Oficina en Bucaramanga | Escritorios para Oficinas | Sillas para Oficinas | Divisiones para Oficinas | Divisiones en Vidrio Bucaramanga' },
			{itemprop: 'description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
	},
    components: {
        'banner-main-page': BannerMainPageComponent,
        'service-main-page': ServiceMainPageComponent,
        'show-product-banner': ShowProductBannerComponent,
        'show-product-gallery': ShowProductGalleryComponent
    },
    data() {
        return {
            data_box: {
                title: 'Diseño de Oficinas',
                description: `
                    Nuestro departamento de investigación, diseño y desarrollo ha logrado construir lineas 
                    de mobiliario innovadoras, acorde con las nuevas tecnologías y el uso adecuado para la buena salud y 
                    la conservación del medio ambiente.
                `,
                box_1: {
                    title: 'Districomer',
                    url_image: 'mobiliario-de-oficina/projects/districomer_project.jpg',
                    link: ''
                },
                box_2: {
                    title: 'Ramedicas',
                    url_image: 'mobiliario-de-oficina/projects/ramedicas_project.jpg',
                    link: ''
                },
                box_3: {
                    title: 'Pesquera del mar',
                    url_image: 'mobiliario-de-oficina/projects/pesquera_project.jpg',
                    link: ''
                },
            },
            // Sillas   
            gallery_chairs: {
                title: 'Sillas para Oficinas',
                description: `
                    <b><i>Cómodas, modernas y de mayor movimiento y flexibilidad en el trabajo.</i></b><br>
                    Las sillas deben acomodarse a los requerimientos de cada uno de los diferentes públicos,
                    para ello, deben aclimatarse de acuerdo a las funciones de la empresa, son clasificadas 
                    en sillas de trabajo u operativas, de dirección o ejecutivo, de invitados, para reuniones 
                    o conferencias, ergonómicas, entre otras.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'office-chairs',
                slides: [
                    'mobiliario-de-oficina/sillas/gerenciales.jpg',
                    'mobiliario-de-oficina/sillas/operativas.jpg',
                    'mobiliario-de-oficina/sillas/presidenciales.jpg',
                    'mobiliario-de-oficina/sillas/interlocutoras.jpg',
                    'mobiliario-de-oficina/sillas/colectivos.jpg'
                ]
            },
            // Recepción
            gallery_reception: {
                title: 'Recepción para Oficinas',
                description: `
                   <b><i>Creamos comodidad y elegancia en recepciones para su oficina.</i></b><br>
                    La recepción es el primer espacio de una oficina, por esa razón es una de las áreas a la que más debemos
                    ponerle atención, ya que la primera impresión para nuestros clientes es de suma importancia.
                    Un buen area de recepción debe estar complementada con una excelente sala de espera con mesas laterales,
                    mesas centrales, sofas y/o sillas para las visitas, colores y texturas cálidss en las paredes y pisos.     
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'furniture-reception',
                slides: [
                    'mobiliario-de-oficina/recepcion/recepcion_1.jpg',
                    'mobiliario-de-oficina/recepcion/recepcion_2.jpg',
                    'mobiliario-de-oficina/recepcion/recepcion_3.jpg'
                ]
            },
            // Salas de espera
            gallery_waiting_room: {
                title: 'Salas de Espera para Oficinas',
                description: `
                    <b><i>Construimos salas elegantes, cómodas y modernas para su empresa.</i></b><br>
                    La sala de espera de cualquier negocio, es la carta de presentación, cuando una persona entra en ella,
                    debe darse cuenta enseguida cual es el giro de la empresa, es por ello que es tan importante la decoración
                    y el mobiliario de la misma. La impresión que deje en la mente de su cliente será crucial al hora de definir los negocios. 
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'waiting-room',
                slides: [
                    'mobiliario-de-oficina/salas-de-espera/sala_1.jpg',
                    'mobiliario-de-oficina/salas-de-espera/sala_2.jpg',
                    'mobiliario-de-oficina/salas-de-espera/sala_3.jpg',
                    'mobiliario-de-oficina/salas-de-espera/sala_4.jpg'
                ]
            },
            // Muebles corativos
            gallery_collaborative_spaces: {
                title: 'Muebles Colaborativos para Oficinas',
                description: `
                    <b><i>Entorno productivo diseñado y amueblado para facilitar el trabajo en equipo.</i></b><br>
                    El diseño interior de una oficina de trabajo es clave. En este sentido, la tendencia actual es privilegiar 
                    el diseño de espacios abiertos, lúdicos, adecuadamente iluminados y flexibles, que potencien el trabajo colaborativo
                    y la retroalimentación de ideas, fomentando así la creatividad y el trabajo en equipo.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'collaborative-spaces',
                slides: [
                    'mobiliario-de-oficina/espacios-colaborativos/colaborativos_1.jpg',
                    'mobiliario-de-oficina/espacios-colaborativos/colaborativos_2.jpg',
                    'mobiliario-de-oficina/espacios-colaborativos/colaborativos_3.jpg',
                    'mobiliario-de-oficina/espacios-colaborativos/colaborativos_4.jpg',
                    'mobiliario-de-oficina/espacios-colaborativos/colaborativos_5.jpg'
                ]
            },
            // Salas de juntas
            gallery_meeting_rooms: {
                title: 'Salas de Juntas',
                description: `
                    <b><i>Funcionales y modernas con mesas y sillas ergonómicas.</i></b>
                    En la sala de juntas se toman las deciciones claves para el correcto direccionamiento del negocio,
                    y también se concretan negocios importantes con los mejores clientes.
                    Poseer una conveniente sala de juntas correctamente diseñada y equipada le permitirá tener la motivación, 
                    la claridad y el poder de persuadir. 
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'meeting-rooms',
                slides: [
                    'mobiliario-de-oficina/salas-de-juntas/salas_de_juntas_1.jpg',
                    'mobiliario-de-oficina/salas-de-juntas/salas_de_juntas_2.jpg',
                    'mobiliario-de-oficina/salas-de-juntas/salas_de_juntas_3.jpg',
                    'mobiliario-de-oficina/salas-de-juntas/salas_de_juntas_4.jpg'
                ]
            },
            // Divisiones
            gallery_office_divisions: {
                title: 'Divisiones para Oficinas',
                description: `
                    <b><i>Desarrollamos espacios multifuncionales que se adapten a su empresa.</i></b><br>
                    Mantener un ambiente de trabajo colaborativo, versátil y productivo, requiere de un gran esfuerzo a la 
                    hora de delimitar las áreas de trabajo. El hacerlo correctamente requiere el análisis claro de las funciones
                    que se realizan,la dependencia y la relación entre los empleados de la empresa, 
                    para ello se deben utilizar materiales que no generen sensaciones de encierro o de trabajo solitario.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'office-divisions',
                slides: [
                    'mobiliario-de-oficina/divisiones-para-oficinas/divisiones_1.jpg',
                    'mobiliario-de-oficina/divisiones-para-oficinas/divisiones_3.jpg',
                    'mobiliario-de-oficina/divisiones-para-oficinas/divisiones_4.jpg',
                    'mobiliario-de-oficina/divisiones-para-oficinas/divisiones_5.jpg',
                    'mobiliario-de-oficina/divisiones-para-oficinas/divisiones_6.jpg'
                ]
            },
            // Muebles operativos
            gallery_operative_furniture: {
                title: 'Muebles Operativos para Oficinas',
                description: `
                    <b><i>Creamos espacios productivos y funcionales.</i></b><br>
                    Si en nuestros puestos de trabajo hemos de pasar 8 o mas horas al día, es justo que este puesto nos brinde la 
                    suficiente comodidad. Recordemos que la comodidad del puesto de trabajo genera en el empleado las ganas de estar
                    trabajando, disminuyendo las ausencias en el trabajo.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'operative-furniture',
                slides: [
                    'mobiliario-de-oficina/operativos/operativos_1.jpg',
                    'mobiliario-de-oficina/operativos/operativos_2.jpg',
                    'mobiliario-de-oficina/operativos/operativos_3.jpg',
                    'mobiliario-de-oficina/operativos/operativos_4.jpg'
                ]
            },
            // Muebles gerenciales
            gallery_managerial_furniture: {
                title: 'Muebles Gerenciales para Oficinas',
                description: `
                    <b><i>Creamos muebles cómodos contemporáneos y elegantes.</i></b><br>
                    Para usted como gerente, su imagen debe ser de las cosas más importantes de la compañía. 
                    Usted es el encargado de tomar las decisiones cruciales para el desarrollo de su empresa, 
                    por lo cual, debe contar con una oficina que transmita su importancia, jerarquía, poder y estatus.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'managerial-furniture',
                slides: [
                    'mobiliario-de-oficina/gerenciales/gerencial_1.jpg',
                    'mobiliario-de-oficina/gerenciales/gerencial_2.jpg',
                    'mobiliario-de-oficina/gerenciales/gerencial_3.jpg',
                    'mobiliario-de-oficina/gerenciales/gerencial_4.jpg'
                ]
            }
        }
    },
}
</script>

