<template>
    <div class="show-product-banner">
        <div class="content">
            <!-- TEXT -->
            <div class="container-text">
                <h1 class="title">
                    <div class="text">
                        Sillas Gerenciales
                    </div>
                    <div
                        data-aos="fade-down" 
                        data-aos-duration="1000"
                        class="line-left"></div>
                    <div
                        data-aos="fade-right"
                        data-aos-delay="500"
                        data-aos-duration="1000" 
                        class="line-bottom"></div>
                </h1>
                <p class="description">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium tenetur enim, laborum quam illum 
                    nisi a necessitatibus dolores, expedita sapiente doloribus autem quas maxime quos voluptate repellendus?
                    Voluptate excepturi temporibus accusamus eaque! Quidem amet corporis suscipit ea, incidunt totam molestiae doloremque nesciunt. Nam, quibusdam voluptas?
                </p>
            </div>
            <!-- IMAGE -->
            <div class="container-image">
                <h2 data-aos="fade-right"
                    data-aos-duration="1000">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </h2>
                <div class="image">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.show-product-banner {
    @extend %default-container-paddings; 
    // border: 1px solid green;

    .content {
        // Text
        .container-text {
            margin-bottom: 2rem;

            .title {
               @extend %title-border;
            }

            .description {

            }   

            @media (min-width: 1024px) {
                width: 30%
            }
        }

        // Image
        .container-image {
            // border: 1px solid green;
             
             h2 { 
                font-family: $SourceSansPro-LightItalic;
                font-size: 1rem;
                padding-bottom: 10px;
                position: relative;
                text-align: right;

                &:before {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 100px;
                    height: 1px;
                    background-color: $main-red-1
                }
            }

            .image {
                width: 100%;
                height: 350px;
                background-size: cover;
                background-repeat: no-repeat;
                background-image: url(~@/assets/images/mobiliario-de-oficina/bg_random.jpg);
                background-position: center center
            }

            @media (min-width: 760px) {
               .image { height: 400px } 
            }

            @media (min-width: 1024px) {
                width: 65%
            }

            @media (min-width: 1200px) {
                .image { height: 450px } 
            }

            @media (min-width: 1200px) {
                .image { height: 550px } 
            }
        }

        @media (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
        }
    }
}

</style>

